import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { getTokens, getUserInfo } from "../api/cognitoAuth";
import Button from "../components/Button";
import Card from "../components/Card";
import { FlamingoAttribution } from "../components/FlamingoAttribution";
import { useAuth } from "../hooks/useAuth";
import rokaLoginBackground from "../images/roka-login-background.jpeg";
import rokaLogo from "../images/roka-management-logo.png";

const COGNITO_DOMAIN = "https://login.rmcounting.no";
const COGNITO_CLIENT_ID = "3bvsvd4s7ofq1nr0ktnpp9cea8";
const CALLBACK_URL = `${window.location.origin}/login`;

// https://docs.aws.amazon.com/cognito/latest/developerguide/federation-endpoints.html
const COGNITO_AUTHORIZE_ENDPOINT = `${COGNITO_DOMAIN}/oauth2/authorize?response_type=code&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  CALLBACK_URL
)}`;

const Login = () => {
  const { user, login } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("code")) {
      // Handle redirect from Cognito Authorize endpoint
      const authCode = searchParams.get("code");
      searchParams.delete("code");
      setSearchParams(searchParams);

      if (!authCode) {
        return;
      }

      // Get a token using authorization_code grant
      getTokens(authCode).then((tokens) => {
        // Get user info using access token
        getUserInfo(tokens.accessToken).then((user) => login(user, tokens));
      });
    }
  }, [login, searchParams, setSearchParams]);

  if (user) {
    return <Navigate to="/" />;
  }

  const handleLogin = () => {
    window.location.href = COGNITO_AUTHORIZE_ENDPOINT;
  };

  return (
    <div className="login-view">
      <div className="background">
        <div className="bg-image">
          <img
            className="roka-login-background"
            src={rokaLoginBackground}
            alt="roka-login-background"
          />
        </div>
        <div className="bg-image-overlay" />
      </div>
      <div className="centered">
        <Card>
          <img
            className="roka-logo"
            src={rokaLogo}
            alt="roka-management-logo"
          />
          <h1>Innlogging kunde</h1>
          <p>Trykk på knappen nedenfor for å logge inn</p>
          <Button onClick={handleLogin}>Logg inn</Button>
        </Card>
      </div>
      <FlamingoAttribution />
    </div>
  );
};

export default Login;
