import {
  differenceInMinutes,
  differenceInSeconds,
  format,
  isEqual,
} from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaArrowTrendUp } from "react-icons/fa6";
import { HiOutlineStatusOffline, HiOutlineStatusOnline } from "react-icons/hi";
import { LuArrowUpToLine } from "react-icons/lu";

import Card from "../../components/Card";
import { useAuth } from "../../hooks/useAuth";
import { LiveMetrics, useRokaApi } from "../../hooks/useRokaApi";

type Liveness = "live" | "delayed" | "disconnected";

type RefreshState = {
  ticksUntilRefresh: number;
  backoffCounter: number;
};

const REFRESH_TICK_INTERVAL = 5000; // 5s

const formatLastUpdated = (date: Date) => {
  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, date);
  const diffInMinutes = differenceInMinutes(now, date);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} sekunder siden`;
  } else if (diffInMinutes < 10) {
    return `${diffInMinutes} minutter siden`;
  } else {
    return format(date, "dd.MM.yyyy HH:mm");
  }
};

const LiveMetricsDashboard = () => {
  const { fetchLiveMetrics } = useRokaApi();
  const {
    user: { name },
  } = useAuth();

  const [liveMetrics, setLiveMetrics] = useState<LiveMetrics | undefined>();
  const [refreshState, setRefreshState] = useState<RefreshState>({
    ticksUntilRefresh: 0,
    backoffCounter: 0,
  });

  const liveMetricsRef = useRef(liveMetrics);
  liveMetricsRef.current = liveMetrics;

  const refreshStateRef = useRef(refreshState);
  refreshStateRef.current = refreshState;

  const decreaseRefreshTicks = () =>
    setRefreshState((prev) => ({
      ...prev,
      ticksUntilRefresh: prev.ticksUntilRefresh - 1,
    }));

  const increaseRefreshBackoff = () =>
    setRefreshState((prev) => ({
      backoffCounter: Math.min(prev.backoffCounter + 1, 3),
      ticksUntilRefresh: Math.min(6 ** prev.backoffCounter, 60), // 1 (5s) -> 6 (30s) -> 36 (3min) -> 60 (5min)
    }));

  const resetRefreshBackoff = () =>
    setRefreshState({
      backoffCounter: 0,
      ticksUntilRefresh: 0,
    });

  // Schedule regular refreshing of metrics + liveness
  const refreshLiveMetrics = async () => {
    const { ticksUntilRefresh } = refreshStateRef.current;

    if (ticksUntilRefresh > 0) {
      console.log(`Waiting for ${ticksUntilRefresh} ticks before refreshing`);
      return decreaseRefreshTicks();
    }

    try {
      const prevLiveMetrics = liveMetricsRef.current;
      const newLiveMetrics = await fetchLiveMetrics(name);
      setLiveMetrics(newLiveMetrics);

      // If lastUpdated is undefined it means this is an event with no data yet, i.e. not live yet.
      // In this case, backoff to avoid unecessary refreshing.
      if (newLiveMetrics.lastUpdated === undefined) {
        console.log("Event has no data yet, increasing backoff", newLiveMetrics);
        return increaseRefreshBackoff();
      }

      if (
        prevLiveMetrics !== undefined &&
        prevLiveMetrics.lastUpdated !== undefined
      ) {
        // Compare new and previous metrics to determine whether we should be backing off
        if (isEqual(prevLiveMetrics.lastUpdated, newLiveMetrics.lastUpdated)) {
          console.log("No new data since last fetch, increasing backoff");
          return increaseRefreshBackoff();
        }
      }

      // Else reset the backoff counter
      return resetRefreshBackoff();
    } catch (error) {
      console.error("Error fetching data. Increasing backoff", error);
      return increaseRefreshBackoff();
    }
  };

  useEffect(() => {
    // Refresh metrics immediately on component mount
    refreshLiveMetrics();

    // Schedule automatic refresh
    const refreshTimer = setInterval(refreshLiveMetrics, REFRESH_TICK_INTERVAL);

    // Clean up the refresh timer on component unmount
    return () => {
      clearInterval(refreshTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once

  const liveness: Liveness = useMemo(() => {
    if (liveMetrics === undefined) {
      return "disconnected";
    }
    const { lastUpdated } = liveMetrics;

    if (lastUpdated == null) {
      return "disconnected";
    }

    const now = new Date();
    const secondsDiff = differenceInSeconds(now, lastUpdated);
    const minutesDiff = differenceInMinutes(now, lastUpdated);

    if (minutesDiff >= 5) {
      return "disconnected";
    } else if (secondsDiff > 60) {
      return "delayed";
    } else {
      return "live";
    }
  }, [liveMetrics]);

  if (isEmpty(name)) {
    return <div>No "name" for user.</div>;
  }

  if (liveMetrics === undefined) {
    return <div>Loading ...</div>;
  }

  const {
    currentOccupancy,
    maxOccupancyToday,
    occupancyDiffLastHour,
    lastUpdated,
  } = liveMetrics;

  // TODO: Re-factor
  let livenessIndicator;
  switch (liveness) {
    case "live":
    case "delayed":
      livenessIndicator = <HiOutlineStatusOnline />;
      break;
    case "disconnected":
      livenessIndicator = <HiOutlineStatusOffline />;
      break;
  }

  const lastUpdatedString =
    lastUpdated != null
      ? `Sist oppdatert: ${formatLastUpdated(lastUpdated)}`
      : `Foreløpig ingen data å vise.`;

  return (
    <div className="live-metrics-dashboard">
      <div className={`last-updated`}>
        <span className={`indicator ${liveness}`}>{livenessIndicator}</span>
        <span className="time">{lastUpdatedString}</span>
      </div>
      <div className="metrics">
        <div className="metric-1">
          <span className="metric-header">
            <span className="metric-icon">
              <HiOutlineStatusOnline />
            </span>
            Antall inne nå
          </span>
          <span className="value">{`${currentOccupancy}`}</span>
        </div>
        <Card className="metric-2">
          <span className="metric-header">
            <span className="metric-icon">
              <LuArrowUpToLine />
            </span>
            Max i dag
          </span>
          <span className="value">{maxOccupancyToday}</span>
        </Card>
        <Card className="metric-3">
          <span className="metric-header">
            <span className="metric-icon">
              <FaArrowTrendUp />
            </span>
            Endring siste timen
          </span>
          <span className="value">{occupancyDiffLastHour}</span>
        </Card>
      </div>
    </div>
  );
};

export default LiveMetricsDashboard;
