import { useEffect, useRef } from "react";

import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { redirectToCognitoLogout, refreshTokens } from "../api/cognitoAuth";
import { useLocalStorage } from "./useLocalStorage";

export type Tokens = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresAt: number; // ms since epoch
};

export type User = {
  username: string; // only used for display
  name: string; // e.g. 'Tons of Rock', used for lookups
};

type AuthContextType = {
  user: User;
  tokens: Tokens;
  login: (user: User, tokens: Tokens) => void;
  logout: () => void;
  setTokens: (tokens: Tokens) => void;
};

const AuthContext = createContext<AuthContextType>({
  user: { username: "", name: "" },
  tokens: {
    accessToken: "",
    idToken: "",
    refreshToken: "",
    expiresAt: 0,
  },
  login: (user: User, tokens: Tokens) => {},
  logout: () => {},
  setTokens: (tokens: Tokens) => {},
});

type Props = {
  children?: React.ReactNode;
};
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [tokens, setTokens] = useLocalStorage("tokens", null);
  const navigate = useNavigate();
  const refreshTokenTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  // handle token refresh, should trigger when tokens update
  useEffect(() => {
    if (!tokens) return;
    const refreshTime = tokens.expiresAt - Date.now() - 5 * 60 * 1000; // 5 minutes before expiration

    if (refreshTime > 0) {
      // Refresh token at refreshTime
      refreshTokenTimeoutIdRef.current = setTimeout(() => {
        refreshTokens(tokens.refreshToken).then(setTokens);
      }, refreshTime);

      // Ensure refresh is cancelled in the event of unmount
      return () => {
        if (refreshTokenTimeoutIdRef.current) {
          clearTimeout(refreshTokenTimeoutIdRef.current);
        }
      };
    }
  }, [tokens, setTokens]);

  const value = useMemo(() => {
    // call this function when you want to authenticate the user
    const login = async (user: User, tokens: Tokens) => {
      setUser(user);
      setTokens(tokens);
      navigate("/");
    };

    // call this function to sign out logged in user
    const logout = async () => {
      // await revokeToken(tokens.refreshToken);
      // cancel any pending token refresh
      if (refreshTokenTimeoutIdRef.current) {
        clearTimeout(refreshTokenTimeoutIdRef.current);
      }
      setUser(null);
      setTokens(null);
      redirectToCognitoLogout();
      // navigate("/login", { replace: true });
    };

    return {
      user,
      tokens,
      login,
      logout,
      setTokens,
    };
  }, [user, tokens, navigate, setUser, refreshTokenTimeoutIdRef, setTokens]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
