import { format, formatISO } from "date-fns";
import { ChartDataV2 } from "../api/chartData";
import { refreshTokens } from "../api/cognitoAuth";
import { useAuth } from "../hooks/useAuth";
import { groupBy } from "lodash";

type GetLiveDataResponseBody = {
  currentOccupancy: number; // last seen value
  maxOccupancyToday: number; // last seen value
  occupancyDiffLastHour: number; // diff last hour (starting at "lastUpdated")
  lastUpdated: string; // timestamp of last received data
};

type GetLiveDataResponse = {
  statusCode: number;
  body: GetLiveDataResponseBody;
};

export type LiveMetrics = {
  currentOccupancy: number; // last seen value
  maxOccupancyToday: number; // last seen value
  occupancyDiffLastHour: number; // diff last hour (starting at "lastUpdated")
  lastUpdated: Date | undefined; // timestamp of last received data
};

type OccupancyEntryV2 = {
  timestamp: string;
  occupancy: number;
};

type GetOccupancyDataResponseEntries = {
  id: string;
  entries: OccupancyEntryV2[];
};

type GetOccupancyDataResponseBody = {
  sensors: GetOccupancyDataResponseEntries[]
}

type GetOccupancyDataResponse = {
  statusCode: number;
  body: GetOccupancyDataResponseBody;
};

type GetStateResponseBody = {
  name: string;
  reset_time: Date;
};

type GetStateResponse = {
  statusCode: number;
  body: GetStateResponseBody;
};

export type RokaState = {
  resetTime: Date;
};

export const useRokaApi = () => {
  const { tokens, setTokens, logout } = useAuth();

  const fetchWithToken = async (
    url: string,
    options: any = {}
  ): Promise<Response> => {
    const defaultOptions = {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
        "Content-Type": "application/json",
      },
    };

    options = {
      ...defaultOptions,
      ...options,
    };

    let response;
    try {
      response = await fetch(url, options);
    } catch (error) {
      console.error("Fetch failed", error);
    }

    if (!response || response.status === 401) {
      // Token expired, try refreshing the token
      try {
        const newTokens = await refreshTokens(tokens.refreshToken);
        setTokens(newTokens);
        options.headers["Authorization"] = `Bearer ${newTokens.idToken}`;
        response = await fetch(url, options);
      } catch (error) {
        console.error("Unable to refresh tokens, logging out..", error);
        logout();
        throw error;
      }
    }

    return response;
  };

  const fetchChartDataV2 = async (locationName: string, startTime: Date, period: "day" | "hour" = "hour", sensorFilter: string | null ): Promise<ChartDataV2> => {
    let path = "https://api.rmcounting.no/v1/data/occupancy";
    path += `?name=${encodeURIComponent(locationName)}`;
    path += `&startTime=${encodeURIComponent(formatISO(startTime))}`;
    path += `&period=${period}`;

    const response = await fetchWithToken(path);
    const result = await response.json();
    // TODO: Validate response

    const parsedResponse = result as GetOccupancyDataResponse;
    const { sensors }  = parsedResponse.body;

    var flattened = sensors.flatMap((sensor) =>
      sensorFilter === null || sensorFilter === ''
        ? (sensor.entries.map((entry) => { return entry }))
        : sensor.id === sensorFilter
          ? (sensor.entries.map((entry) => { return entry }))
          : []
    );

    var groupedByTimestamp = groupBy(flattened, ({timestamp}) => timestamp);

    var sumByTimeStamp = Object.keys(groupedByTimestamp)
      .map(key => ({
        timestamp: key,
        occupancy: groupedByTimestamp[key].sort(function(a,b){ return Date.parse(b.timestamp) - Date.parse(a.timestamp) }).reduce((sum, entry) => sum + entry.occupancy, 0)
      }));

    var sensorIds = sensors.map((sensor) => sensor.id);

    return {
      sensors: sensorIds,
      labels: sumByTimeStamp.map((entry) =>
        format(new Date(entry.timestamp), "HH:mm")
      ),
      data: sumByTimeStamp.map((entry) => entry.occupancy),
    };
  };

  const fetchLiveMetrics = async (locationName: string): Promise<LiveMetrics> => {
    let path = "https://api.rmcounting.no/v1/data/live";
    path += `?name=${encodeURIComponent(locationName)}`;
    const response = await fetchWithToken(path);
    const result = await response.json();
    // TODO: Validate response

    // Pretty weird, but we can actually get a 404 from lambda
    if (result.statusCode === 404) {
      // This should only happen when there have been no data entries for the event.
      // We just want to display something some sort of "initial" state here.
      return {
        currentOccupancy: 0,
        maxOccupancyToday: 0,
        occupancyDiffLastHour: 0,
        lastUpdated: undefined,
      };
    }

    // Parse data
    const parsedResponse = result as GetLiveDataResponse;
    const body = parsedResponse.body;

    return {
      ...body,
      lastUpdated: new Date(body.lastUpdated),
    };
  };

  const fetchState = async (): Promise<RokaState> => {
    const path = "https://api.rmcounting.no/v1/state/user";
    const response = await fetchWithToken(path);
    const result = await response.json();
    // TODO: Validate response

    // Parse data
    const parsedResponse = result as GetStateResponse;
    const body = parsedResponse.body;

    return {
      resetTime: body.reset_time,
    };
  };

  const updateState = async (state: RokaState) => {
    let path = "https://api.rmcounting.no/v1/state/user";
    await fetchWithToken(path, {
      method: "POST",
      body: JSON.stringify({
        reset_time: format(state.resetTime, "yyyy-MM-dd'T'HH:mm"),
      }),
    });
  };

  return {
    fetchChartDataV2,
    fetchLiveMetrics,
    fetchState,
    updateState,
  };
};
