import { Tokens, User } from "../hooks/useAuth";

const COGNITO_DOMAIN = 'https://login.rmcounting.no';
const COGNITO_CLIENT_ID = '3bvsvd4s7ofq1nr0ktnpp9cea8';
const CALLBACK_URL = `${window.location.origin}/login`;

// https://docs.aws.amazon.com/cognito/latest/developerguide/federation-endpoints.html
const COGNITO_TOKEN_ENDPOINT = `${COGNITO_DOMAIN}/oauth2/token`;
const COGNITO_USER_INFO_ENDPOINT = `${COGNITO_DOMAIN}/oauth2/userInfo`;
const COGNITO_REVOKE_TOKEN_ENDPOINT = `${COGNITO_DOMAIN}/oauth2/revoke`;
const COGNITO_LOGOUT_ENDPOINT = `${COGNITO_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${encodeURIComponent(CALLBACK_URL)}`;


export const getTokens = async (authorizationCode: string): Promise<Tokens> =>
    fetch(COGNITO_TOKEN_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: COGNITO_CLIENT_ID,
            redirect_uri: CALLBACK_URL,
            code: authorizationCode
        })
    })
        // TODO: Handle common errors
        .then(res => res.json())
        .then(json => {
            /* Example response:
              { 
                "access_token":"eyJra1example", 
                "id_token":"eyJra2example",
                "refresh_token":"eyJj3example",
                "token_type":"Bearer", 
                "expires_in":3600
                }
             */
            return {
                accessToken: json['access_token'],
                idToken: json['id_token'],
                refreshToken: json['refresh_token'],
                expiresAt: Date.now() + (parseInt(json['expires_in'], 10) * 1000),
            }
        });

export const getUserInfo = async (accessToken: string): Promise<User> =>
    fetch(COGNITO_USER_INFO_ENDPOINT, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
        .then(res => res.json())
        .then(json => {
            /* Example response:
              {
                  "sub": "[UUID]",
                  "email_verified": "true",
                  "custom:mycustom1": "CustomValue",
                  "phone_number_verified": "true",
                  "phone_number": "+12065551212",
                  "email": "bob@example.com",
                  "username": "bob"
              }
             */
            return {
                username: json['username'],
                name: json['name'],
            };
        });

export const refreshTokens = (refreshToken: string): Promise<Tokens> =>
    fetch(COGNITO_TOKEN_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: COGNITO_CLIENT_ID,
            refresh_token: refreshToken
        })
    })
        // TODO: Handle common errors
        .then(res => res.json())
        .then(json => {
            /* Example response:
              { 
                "access_token":"eyJra1example", 
                "id_token":"eyJra2example",
                "expires_in":3600
                }
             */
            return {
                accessToken: json['access_token'],
                idToken: json['id_token'],
                expiresAt: Date.now() + (parseInt(json['expires_in'], 10) * 1000),
                refreshToken,
            }
        });

export const revokeToken = async (refreshToken: string) => {
    try {
        await fetch(COGNITO_REVOKE_TOKEN_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                token: refreshToken,
                client_id: COGNITO_CLIENT_ID,
            })
        });
    } catch (error) {
        console.error('Error revoking token', error);
    }
};

export const redirectToCognitoLogout = () => {
    window.location.href = COGNITO_LOGOUT_ENDPOINT;
}