import {
    CategoryScale,
    Chart,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options: ChartOptions<"line"> = {
    responsive: true,
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    hover: {
        mode: 'index',
        intersect: false,
    },
    interaction: {
        mode: 'y'
    },
    scales: {
        y: {
            ticks: {
                display: true,
            },
            grid: {
                display: false,
            }
        },
        x: {
            ticks: {
                maxTicksLimit: 5,
                align: 'start',
            },
            grid: {
                display: false,
            }
        }
    }
};

type LineChartProps = {
    data: ChartData<"line", (number | [number, number] | null)[], unknown>
}

const LineChart = (props: LineChartProps) => {
    useEffect(() => {
        // Cleanup function to destroy all chart instances when component unmounts
        return () => {
            Object.keys(Chart.instances).forEach(key => {
                Chart.instances[key].destroy();
            });
        };
    }, []);

    return (
        <div className={`roka-metrics-chart`}>
            <Line
                options={options}
                data={props.data}
                height={100}
            />
        </div>
    );
}

export default LineChart;